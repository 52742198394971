import './bootstrap';
import $ from 'jquery';


import { entrance } from "scroll-entrance/dist/scroll-entrance";
import 'bootstrap/dist/js/bootstrap.bundle';

import 'localizationjs';
import validate from 'jquery-validation'

import { tns } from "tiny-slider/src/tiny-slider"
window.tns = tns;

import './script';
import './walid_contact';
import './modal_form';
import './menu_scroller';
import './top_scroll'
import './entrance-effect';
import 'intl';
import 'intl/locale-data/jsonp/en.js';

// import * as bootstrap from "bootstrap";
// import "bootstrap";
// import 'bootstrap/dist/js/bootstrap.bundle.min';

