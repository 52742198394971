$(document).ready(function() {

    function scrollToSection(hash, isSamePage) {
        if (hash === '#' || !hash) {
            console.log('Invalid hash:', hash);
            return; // Exit the function if hash is invalid
        }

        var target = $(hash);
        var navbarHeight = $('.navbar').outerHeight();
        // Use a negative offset for navigation from other pages to scroll a bit higher
        var offset = isSamePage ? 200 : 400;

        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - navbarHeight - offset
            }, 1000);
        }
    }

    function handleNavigation(pageUrl) {
        var hashIndex = pageUrl.indexOf("#");
        var isSamePage = (window.location.pathname === pageUrl.substring(0, hashIndex)) || hashIndex === -1;

        if (hashIndex !== -1) {
            var hash = pageUrl.substring(hashIndex);
            if (hash !== '#') { // Ensure hash is not just '#'
                scrollToSection(hash, isSamePage);
            }
        }
    }

    // Handle direct URL access with hash
    $(window).on('load', function() {
        handleNavigation(window.location.href);
    });

    // Handle clicks on anchor tags
    $('a[href*="#"]').on('click', function(e) {
        e.preventDefault();
        handleNavigation(this.href);
    });

    $('a.dropdown-item').click(function(event) {
        var pageUrl = $(this).attr('href');
        var hashIndex = pageUrl.indexOf("#");
        if (hashIndex > 0) {
            var basePath = pageUrl.substring(0, hashIndex);
            var hash = pageUrl.substring(hashIndex);
            if (hash !== '#' && window.location.pathname !== basePath) {
                event.preventDefault();
                window.location.href = basePath + hash;
            }
        }
    });
});
