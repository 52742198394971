$(document).ready(function () {
    $("#contactModalForm").validate({
        rules: {
            firstname: "required",
            lastname: "required",
            email: {
                required: true,
                email: true
            },
            phone: {
                required: true,
                digits: true,
                minlength: 9,
                maxlength: 15
            },
            modal_agree_regulations1: "required",
            modal_agree_regulations2: "required",
        },
        messages: {
            firstname: "Proszę podać swoje imię",
            lastname: "Proszę podać swoje nazwisko",
            email: {
                required: "Proszę podać swój adres e-mail",
                email: "Proszę podać prawidłowy adres e-mail"
            },
            phone: {
                required: "Proszę podać swój numer telefonu",
                digits: "Proszę podać tylko cyfry",
                minlength: "Twój numer telefonu musi składać się przynajmniej z 10 cyfr",
                maxlength: "Twój numer telefonu nie może przekraczać 15 cyfr"
            },
            modal_agree_regulations1: {
                required: "Musisz zaakceptować Politykę prywatności",
            },
            modal_agree_regulations2: {
                required: "Musisz wyrazić zgodę na otrzymywanie informacji",
            }

        },
        errorPlacement: function(error, element) {
            if (element.attr("type") == "checkbox") {
                // error.insertAfter(element.parents('label'));
                error.insertAfter(element.closest('.form-check'));
            } else {
                error.insertAfter(element);
            }
        },
        submitHandler: function(form) {
        $('#submitBtn').prop('disabled', true).text('Wysyłanie wiadomośći...');
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            type: "POST",
            url: $(form).attr('action'),
            data: $(form).serialize(),
            success: function(response) {
                if (response.success) {
                    window.open(response.redirectUrl, '_blank');
                    window.location.href = '/';
                } else {

                    alert('Please fix the errors in the form.');
                    $('#submitBtn').prop('disabled', false).text('Submit');
                }
            },
            error: function() {

                alert('There was an error processing your request. Please try again.');
                $('#submitBtn').prop('disabled', false).text('Submit');
            }
        });

        return false;
    },

    });

    function setRedirectUrl(url) {
        document.getElementById('redirectUrl').value = url;
    }

    function setPdfType(type) {
        $("#pdfType").val(type);
    }

    var modal = $('#contactModal');
    $('.main_btn_modal_open').on('click', function(event) {
        event.preventDefault();
        var pdfType = $(this).data('pdf-type');
        $("#pdfType").val(pdfType);
        modal.show();
    });

    $('.modal-close').on('click', function() {
        modal.hide();
    });

    $(window).on('click', function(event) {
        if (event.target.id === 'contactModal') {
            modal.hide();
        }
    });
});
