$(document).ready(function() {
    $("#contactForm").submit(function(e) {
        e.preventDefault();
        $('.is-invalid').removeClass('is-invalid');
        $('.invalid-feedback').empty();

        var firstname = $('#contactForm_firstname').val();
        var errors = 0;
        $('.form-group--register-firstname .invalid-feedback').empty();
        if (!firstname) {
            $('#contactForm_firstname').addClass('is-invalid');
            var errors = 1;
            $('.form-group--register-firstname .invalid-feedback').html('<p>To pole jest wymagane</p>');
        } else {
            if (firstname.length < 3) {
                $('#contactForm_firstname').addClass('is-invalid');
                var errors = 1;
                $('.form-group--register-firstname .invalid-feedback').html('<p>Imię musi zawierać minimum trzy litery.</p>');
            } else {
                $('#contactForm_firstname').removeClass('is-invalid');
            }


        }

        var lastname = $('#contactForm_lastname').val();

        if (!lastname) {
            $('#contactForm_lastname').addClass('is-invalid');
            var errors = 1;
            $('.form-group--register-lastname .invalid-feedback').html('<p>To pole jest wymagane</p>');
        } else {
            if (lastname.length < 3) {
                $('#contactForm_lastname').addClass('is-invalid');
                var errors = 1;
                $('.form-group--register-lastname .invalid-feedback').html('<p>Nazwisko musi zawierać minimum trzy litery.</p>');
            } else {
                $('#contactForm_lastname').removeClass('is-invalid');
            }
        }


        var email = $('#contactForm_email').val();
        if (!email) {
            $('#contactForm_email').addClass('is-invalid');
            var errors = 1;
            $('.form-group--register-email .invalid-feedback').html('<p>To pole jest wymagane</p>');
        }else{
            if (IsEmail(email)) {
                $('#contactForm_email').removeClass('is-invalid');
            } else {
                $('#contactForm_email').addClass('is-invalid');
                $('.form-group--register-email .invalid-feedback').html('<p>Wpisz poprawny adres e-mail</p>');
                var errors = 1;
            }
        }

        var phone = $('#contactForm_phone').val();
        if (phone) {
            if (phone.length < 7) {
                $('#contactForm_phone').addClass('is-invalid');
                $('.form-group--register-phone .invalid-feedback').html('<p>Podany numer telefonu jest za krótki</p>');
                var errors = 1;
            } else {
                if (!validatePhone(phone)) {
                    $('#contactForm_phone').addClass('is-invalid');
                    $('.form-group--register-phone .invalid-feedback').html('<p>Wpisz poprawny 9-cyfrowy numer telefonu</p>');
                    var errors = 1;
                } else {
                    $('#contactForm_phone').removeClass('is-invalid');
                }
            }
        }


        var message = $('#contactForm_QuestionHelp').val();

        var agree1 = $('#contactForm_AgreeRegulations1').prop('checked');

        var agreeVal1 = 0;

        if (agree1) {
            agreeVal1 = 1;
        }

        var phoneCheckbox = $('#contactForm_phone');


        if (!email && !phone) {
            var errors = 1;
            $('#contactForm_email').addClass('is-invalid');
            $('.form-group--register-email .invalid-feedback').html('<p>Proszę podać dane na co najmniej jeden kanał komunikacji</p>').show();
            $('#contactForm_phone').addClass('is-invalid');
            $('.form-group--register-phone .invalid-feedback').html('<p>Proszę podać dane na co najmniej jeden kanał komunikacji</p>').show();
        }

        var agree1 = $('#contactForm_AgreeRegulations1').prop('checked');
        if (!agree1) {
            $('#contactForm_AgreeRegulations1').addClass('is-invalid');
            $('.contactForm-agree-regulations1').html('<p>Musisz wyrazić zgodę na "Politykę prywatności i administracji danych osobowych".</p>').show();
            var errors = 1;
        } else {
            $('#contactForm_AgreeRegulations1').removeClass('is-invalid');
            $('.contactForm-agree-regulations1').empty().hide();
        }

        // Validation for the second agreement checkbox
        var agree2 = $('#contactForm_AgreeRegulations2').prop('checked');
        if (!agree2) {
            $('#contactForm_AgreeRegulations2').addClass('is-invalid');
            $('.contactForm-agree-regulations2').html('<p>Musisz wyrazić zgodę na otrzymywanie informacji.</p>').show();
            var errors = 1;
        } else {
            $('#contactForm_AgreeRegulations2').removeClass('is-invalid');
            $('.contactForm-agree-regulations2').empty().hide();
        }

        console.log(errors);
        if (errors == 0) {
            $.ajax({
                type: 'POST',
                url: '/kontakt',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    phone: phone,
                    message: message,
                    agree_regulations1: (agreeVal1 ? 'yes' : 'no'),
                    agree_regulations2: (agreeVal1 ? 'yes' : 'no')
                },
                success: function(result) {

                    console.log(result.success);
                    if (result.success) {



                        $('#contactForm_firstname').val('');
                        $('#contactForm_lastname').val('');
                        $('#contactForm_email').val('');
                        $('#contactForm_phone').val('');
                        $('#contactForm_QuestionHelp').val('');
                        $('#contactForm_AgreeRegulations1').prop('checked', false);
                        $('#contactForm_AgreeRegulations2').prop('checked', false);

                        alert('Dziękuje za wysłanie formularza');

                    } else {
                        alert('Zapis nie powiódł się. Spróbuj ponownie');
                    }
                }
            });
        }
    });
	$('#contactForm_QuestionHelp').on('input', function() {
        currentLength = $(this).val().length;
        const remaining = 1000 - currentLength;
        $('#contactForm_QuestionCounter').text(`Wykorzystano ${currentLength}/1000 znaków`);
    });

    function IsEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    function validatePhone(txtPhone) {
        var regex = /^[0-9]+$/
        return regex.test(txtPhone);
    }
});
